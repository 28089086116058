import textStyles from 'config/branding/textStyles';
import useAccountQuery from 'queries/useAccountQuery';
import useTeamStoreUrlQuery from 'queries/useTeamStoreUrlQuery';
import Link from '@activebrands/core-web/components/Link';
import media from '@activebrands/core-web/config/media';
import overlay from '@activebrands/core-web/libs/overlay';
import { styled, useStyletron } from '@activebrands/core-web/libs/styletron';
import StateIcon from 'components/icons/StateIcon';
import Heading from 'components/text/Heading';
import Paragraph from 'components/text/Paragraph';

const Wrapper = styled('div', {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    zIndex: 'calc(var(--zindex-header)',
    width: '328px',
    backgroundColor: 'var(--color-bg-password-overlay)',
    borderRadius: '2px',
    padding: '16px',

    [media.min['mobile.lg']]: {
        minWidth: '352px',
    },
});

const StyledLink = styled(Link, {
    marginBottom: '8px',
    textDecoration: 'underline 1px var(--color-underline-auth)',
    textUnderlineOffset: '4px',
    ...textStyles['Primary/14_100_-3'],
});

const CloseButton = styled('button', {
    display: 'flex',
    alignItems: 'center',
    width: '40px',
    height: '100%',
    cursor: 'pointer',
    justifyContent: 'flex-end',
});

const ValidateTeamAccountSuccessOverlay = () => {
    const [css] = useStyletron();
    const {
        validateTeamAccountSuccessModalTitle,
        validateTeamAccountSuccessModalContent,
        validateTeamAccountSuccessModalButtonLabel,
    } = useAccountQuery();
    const { teamStoreCategoryPage: teamStoreCategoryPagePath } = useTeamStoreUrlQuery();

    return (
        <Wrapper>
            <div
                className={css({
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'space-between',
                    marginBottom: '16px',
                })}
            >
                {validateTeamAccountSuccessModalTitle && (
                    <Heading>
                        <span style={textStyles['Primary/24_120_-4']}>{validateTeamAccountSuccessModalTitle}</span>
                    </Heading>
                )}

                <CloseButton type="button">
                    <StateIcon
                        size="16px"
                        state="reset"
                        onClick={() => overlay.close('validate-team-account-success')}
                    />
                </CloseButton>
            </div>

            {validateTeamAccountSuccessModalContent && (
                <Paragraph $style={{ marginBottom: '24px' }} fontKeys="Primary/18_100_-3">
                    {validateTeamAccountSuccessModalContent}
                </Paragraph>
            )}

            {validateTeamAccountSuccessModalButtonLabel && (
                <StyledLink
                    to={teamStoreCategoryPagePath}
                    onClick={() => overlay.close('validate-team-account-success')}
                >
                    {validateTeamAccountSuccessModalButtonLabel}
                </StyledLink>
            )}
        </Wrapper>
    );
};

export default ValidateTeamAccountSuccessOverlay;
